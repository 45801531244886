import { useTranslation } from 'react-i18next';
import { Check, ChevronsUpDown } from 'lucide-react';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { ShortRestaurantCompanyWithLocations } from '@durma-soft/gros-sdk/dist/types/restaurants';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/shadcn-ui/popover';
import { Spinner } from '@/components/shared/spinner';
import { Button } from '@/components/shared/shadcn-ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/shared/shadcn-ui/command';

import { cn } from '@/utils/helpers';

interface SelectSingleLocationProps {
  companies: ShortRestaurantCompanyWithLocations[];
  isLoading?: boolean;
  locationId: number | null;
  setLocationId: Dispatch<SetStateAction<number | null>>;
}

export const SelectSingleLocation = ({
  companies,
  isLoading,
  locationId,
  setLocationId,
}: SelectSingleLocationProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const selectedLocation = useMemo(() => {
    if (!locationId) return null;
    return (
      companies
        .map((company) => company.locations)
        .flat()
        .find((location) => location.id === locationId) || null
    );
  }, [locationId, companies]);

  if (isLoading) return <Spinner />;

  return (
    <Popover modal open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className="">
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className=" justify-between font-normal"
        >
          {selectedLocation
            ? selectedLocation.name
            : t('common.choose-location-placeholder')}
          <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[320px] p-0">
        <Command>
          <CommandInput placeholder={t('common.search-placeholder')} />
          <CommandEmpty>{t('company-locations.not-found')}</CommandEmpty>
          <CommandList>
            {companies &&
              companies.map((company) => (
                <CommandGroup heading={company.name} key={company.id}>
                  {company.locations.map((location) => (
                    <CommandItem
                      onSelect={() => {
                        if (location.id === locationId) {
                          setLocationId(null);
                        } else {
                          setLocationId(location.id);
                        }
                        setOpen(false);
                      }}
                      key={location.id}
                      value={String(location.name) + String(location.id)}
                    >
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          locationId === location.id
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                      {location.name}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
