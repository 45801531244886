import { format } from 'date-fns';
import { useState, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UseDatePickerState {
  storeInUrl?: boolean;
}

export const useDatePickerState = ({
  storeInUrl = true,
}: UseDatePickerState = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [dateState, setDateState] = useState(format(Date.now(), 'yyyy-MM-dd'));
  const dateParam =
    searchParams.get('date') || format(Date.now(), 'yyyy-MM-dd');

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useLayoutEffect(() => {
    if (!storeInUrl) return;
    if (!searchParams.has('date')) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        date: format(Date.now(), 'yyyy-MM-dd'),
      });
    }
  }, [searchParams, setSearchParams, storeInUrl]);

  const setDate = (newDate: string) => {
    setDateState(newDate);
    if (!storeInUrl) return;
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      date: newDate,
    });
  };

  return {
    date: storeInUrl ? dateParam : dateState,
    datePickerOpen,
    setDate,
    setDatePickerOpen,
  };
};
