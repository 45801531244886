import { FocusWindowOverlay } from '@/components/shared/focus-window-overlay';
import { DatePicker } from '@/components/shared/date-picker';
import { InputAddon } from '@/components/shared/input-addon';
import { Modal } from '@/components/shared/modal';
import { SelectShift } from '@/components/shared/select-shift';
import { Button } from '@/components/shared/shadcn-ui/button';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';
import { TRACK_TIMER_DURATION_LOCAL_STORAGE_KEY } from '@/config/global/storage';
import { useCameraBarcodeScanner } from '@/hooks/use-camera-barcode-scanner';
import { isNotEmpty } from '@/utils/helpers';
import {
  isAxiosError,
  TRACK_ERROR,
  TrackError,
  useAuthUserRestaurant,
  useGetRestaurantTrackLocationsSuspenseQuery,
  useRestaurantTrackScanQRCodeMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import { format } from 'date-fns';
import {
  FormEvent,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useIdle } from 'react-use';
import { toast } from 'sonner';
import { PickedUpOrders } from '@/components/restaurant/track/picked-up-orders';
import { TrackIdleCard } from '@/components/shared/track-idle-card';
import { Card } from '@/components/shared/shadcn-ui/card';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { TrackSuccess } from '@/components/shared/track-success';
import { UnknownTrackError } from '@/components/shared/unknown-track-error';
import { GenericTrackError } from '@/components/shared/generic-track-error';
import { QrCode } from 'lucide-react';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useBarcodeScanner } from '@/hooks/use-barcode-scanner';

const RestaurantTrack = () => {
  const uid = useId();
  const { t } = useTranslation();
  const { id: restaurant_id, primary_track_location_id } =
    useAuthUserRestaurant();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isPaused, setIsPaused] = useState(false);
  const [pausedSeconds, setPausedSecondsState] = useState(() => {
    const localStorageValue = localStorage.getItem(
      TRACK_TIMER_DURATION_LOCAL_STORAGE_KEY,
    );
    if (!localStorageValue) return 5;
    if (typeof JSON.parse(localStorageValue) === 'number')
      return JSON.parse(localStorageValue);
    return 5;
  });
  const [pausedTimestamp, setPausedTimestamp] = useState<number | null>(null);
  const setPausedSeconds = (seconds: number) => {
    setPausedSecondsState(seconds);
    localStorage.setItem(
      TRACK_TIMER_DURATION_LOCAL_STORAGE_KEY,
      typeof seconds === 'number' ? JSON.stringify(seconds) : JSON.stringify(5),
    );
  };

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [date, setDate] = useState(format(Date.now(), 'yyyy-MM-dd'));
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const [userId, setUserId] = useState('');

  const [selectedShift, setSelectedShift] = useState<RestaurantShift>(() => {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 14) return '1';
    if (currentHour >= 14 && currentHour < 22) return '2';
    return '3';
  });

  const idle = useIdle(1000 * 60 * 10);

  useEffect(() => {
    if (idle) {
      window.location.reload();
    }
  }, [idle]);

  const { data: allTrackLocations } =
    useGetRestaurantTrackLocationsSuspenseQuery({
      restaurant_id,
    });

  if (
    !allTrackLocations.length ||
    !allTrackLocations.some((location) => location.company_locations_count > 0)
  ) {
    throw new Error('Greška pri podešavanju linije za kompaniju.');
  }

  const [selectedTrackLocation] = useState(() => {
    if (!primary_track_location_id) return allTrackLocations[0];
    return (
      allTrackLocations.find(
        (location) => location.id === primary_track_location_id,
      ) || allTrackLocations[0]
    );
  });

  const {
    isOpen: QRScannerOpen,
    isLoading: QRScannerLoading,
    openScanner,
    closeScanner,
  } = useCameraBarcodeScanner();

  const { isIdle, isPending, isSuccess, isError, error, mutate, data, reset } =
    useRestaurantTrackScanQRCodeMutation();

  const trackError: TrackError | null = useMemo(() => {
    if (!isError) return null;
    if (isAxiosError(error)) {
      const possibleTrackErrors = Object.values(TRACK_ERROR);
      if (
        error?.response?.data?.type &&
        possibleTrackErrors.includes(error.response.data.type)
      ) {
        return error.response.data as TrackError;
      }
      return null;
    }
    return null;
  }, [isError, error]);

  const barcodeScannerDisabled =
    isError && !!trackError && trackError.type === TRACK_ERROR.NO_ORDER;

  const onScan = useCallback(
    (code: string) => {
      if (barcodeScannerDisabled || isPaused) return;
      mutate({
        restaurant_id,
        qr_code: code,
        shift: selectedShift,
        restaurant_track_location_id: selectedTrackLocation.id,
        date: date,
      });
    },
    [
      mutate,
      selectedShift,
      restaurant_id,
      selectedTrackLocation.id,
      date,
      barcodeScannerDisabled,
      isPaused,
    ],
  );

  const isValid = useMemo(() => {
    return (
      isNotEmpty(userId) &&
      !Number.isNaN(+userId) &&
      isNotEmpty(selectedShift) &&
      selectedTrackLocation.id &&
      date
    );
  }, [userId, selectedShift, selectedTrackLocation.id, date]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid || isPaused) return;
    try {
      mutate({
        date,
        restaurant_id,
        shift: selectedShift,
        user_id: Number(userId),
        restaurant_track_location_id: selectedTrackLocation.id,
      });
      setUserId('');
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('track.user-id-error'));
    }
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current!);
    }
    if (isSuccess) {
      setIsPaused(true);
      setPausedTimestamp(Date.now());
      timeoutRef.current = setTimeout(() => {
        setIsPaused(false);
      }, pausedSeconds * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useBarcodeScanner({ onScan, enabled: !barcodeScannerDisabled || isPaused });

  return (
    <>
      <Helmet>
        <title>{t('track.title')}</title>
      </Helmet>

      {createPortal(
        <Button
          variant="outline"
          className="order-[-1] font-normal"
          onClick={() => setSettingsModalOpen(true)}
        >
          {t('sidebar.settings')}
        </Button>,
        document.getElementById('header-desktop-nav-items')!,
      )}

      <Modal
        isModalOpen={settingsModalOpen}
        closeModal={() => setSettingsModalOpen(false)}
        title={t('track.settings')}
        description={t('track.settings-details')}
      >
        <div className="flex flex-col justify-center gap-4">
          <div className="flex flex-col items-start gap-3">
            <div className="flex flex-col gap-2">
              <Label htmlFor={uid + '-location'}>{t('track.location')}</Label>
              <Select disabled={true} value={`${selectedTrackLocation.id}`}>
                <SelectTrigger
                  disabled
                  className="inline-flex w-auto min-w-[120px] xl:h-11 xl:text-xl xl:min-w-[200px]"
                  id={uid + '-location'}
                >
                  <SelectValue
                    placeholder={t('employee.company-locations-placeholder')}
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className="xl:[&>*]:text-xl">
                    {allTrackLocations &&
                      allTrackLocations?.map((location) => (
                        <SelectItem key={location.id} value={`${location.id}`}>
                          {location.name}
                        </SelectItem>
                      ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor={uid + '-shift'}>{t('track.shift')}</Label>
              <SelectShift
                id={uid + '-shift'}
                className="inline-flex w-auto min-w-[120px] xl:h-11 xl:text-xl xl:min-w-[200px]"
                groupClassname="xl:[&>*]:text-xl"
                shift={selectedShift}
                setShift={(shift) => {
                  reset();
                  setSelectedShift(shift);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-date-picker'}>{t('track.date')}</Label>
            <div className="max-w-72">
              <DatePicker
                id={uid + '-date-picker'}
                className="inline-flex w-auto min-w-[120px] xl:h-11 xl:text-xl xl:min-w-[200px]"
                open={datePickerOpen}
                onOpenChange={setDatePickerOpen}
                date={new Date(date)}
                setDate={(newDate) => {
                  if (!newDate) return;
                  setDate(format(newDate, 'yyyy-MM-dd'));
                  setDatePickerOpen(false);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-pause-duration'}>
              {t('track.pause-between-orders')}
            </Label>
            <div className="flex items-stretch">
              <Input
                className="flex-1 border-r-0 rounded-tr-none rounded-br-none"
                type="number"
                value={pausedSeconds || ''}
                onChange={(e) => {
                  setPausedSeconds(+e.target.value);
                }}
              />
              <InputAddon position="right">s</InputAddon>
            </div>
          </div>
          <Button
            onClick={() => setSettingsModalOpen(false)}
            className="self-end"
          >
            {t('common.close')}
          </Button>
        </div>
      </Modal>

      <FocusWindowOverlay enabled={false} />
      <PickedUpOrders
        shift={selectedShift}
        date={date}
        trackLocationId={selectedTrackLocation.id}
      />

      <div className="flex-1 flex gap-10 pr-64">
        <div className="flex-1">
          <div>
            {isIdle && <TrackIdleCard />}
            {isPending && (
              <Card className="p-5 space-y-3">
                <Skeleton className="w-full h-8" />
                <Skeleton className="w-full h-8" />
                <Skeleton className="w-full h-8" />
                <Skeleton className="w-full h-8" />
              </Card>
            )}
            {isSuccess && (
              <TrackSuccess
                key={data.user.id}
                user={data.user}
                foods={data.foods}
                isPaused={isPaused}
                pausedTimestamp={pausedTimestamp}
                pausedSeconds={pausedSeconds}
              />
            )}
            {isError && !trackError && <UnknownTrackError />}
            {isError && trackError && (
              <>
                {trackError.type === TRACK_ERROR.INVALID_QR_CODE && (
                  <GenericTrackError
                    title={t('track.errors.invalid-qr-code.heading')}
                    description={t('track.errors.invalid-qr-code.description')}
                    onClose={reset}
                    className="xl:[&>h3]:text-2xl xl:[&>svg]:w-24 xl:[&>svg]:h-24 xl:[&>p]:text-xl"
                  />
                )}
                {trackError.type === TRACK_ERROR.ORDER_ALREADY_PICKED_UP && (
                  <GenericTrackError
                    title={t('track.errors.order-already-picked-up.heading')}
                    description={t(
                      'track.errors.order-already-picked-up.description',
                    )}
                    onClose={reset}
                    className="xl:[&>h3]:text-2xl xl:[&>svg]:w-24 xl:[&>svg]:h-24 xl:[&>p]:text-xl"
                  />
                )}
                {trackError.type === TRACK_ERROR.WRONG_LOCATION && (
                  <GenericTrackError
                    title={t('track.errors.wrong-location.heading')}
                    description={t('track.errors.wrong-location.description')}
                    onClose={reset}
                    className="xl:[&>h3]:text-2xl xl:[&>svg]:w-24 xl:[&>svg]:h-24 xl:[&>p]:text-xl"
                    showSubstituteMeals
                    mode="restaurant"
                    trackLocationId={selectedTrackLocation.id}
                    date={date}
                    userId={trackError.user_id}
                    shift={selectedShift}
                  />
                )}
                {trackError.type === TRACK_ERROR.WRONG_SHIFT && (
                  <GenericTrackError
                    title={t('track.errors.wrong-shift.heading')}
                    description={t('track.errors.wrong-shift.description')}
                    onClose={reset}
                    className="xl:[&>h3]:text-2xl xl:[&>svg]:w-24 xl:[&>svg]:h-24 xl:[&>p]:text-xl"
                    showSubstituteMeals
                    mode="restaurant"
                    trackLocationId={selectedTrackLocation.id}
                    date={date}
                    userId={trackError.user_id}
                    shift={selectedShift}
                  />
                )}
                {trackError.type === TRACK_ERROR.USER_NOT_FOUND && (
                  <GenericTrackError
                    title={t('track.errors.user-not-found.heading')}
                    description={t('track.errors.user-not-found.description')}
                    onClose={reset}
                    className="xl:[&>h3]:text-2xl xl:[&>svg]:w-24 xl:[&>svg]:h-24 xl:[&>p]:text-xl"
                  />
                )}
                {trackError.type === TRACK_ERROR.WRONG_TRACK_LOCATION && (
                  <GenericTrackError
                    title={t('track.errors.wrong-track-location.heading')}
                    description={t(
                      'track.errors.wrong-track-location.description',
                    )}
                    onClose={reset}
                    className="xl:[&>h3]:text-2xl xl:[&>svg]:w-24 xl:[&>svg]:h-24 xl:[&>p]:text-xl"
                  />
                )}
                {trackError.type === TRACK_ERROR.WRONG_COMPANY && (
                  <GenericTrackError
                    title={t('track.errors.wrong-company.heading')}
                    description={t('track.errors.wrong-company.description')}
                    onClose={reset}
                    className="xl:[&>h3]:text-2xl xl:[&>svg]:w-24 xl:[&>svg]:h-24 xl:[&>p]:text-xl"
                  />
                )}
                {trackError.type === TRACK_ERROR.NO_ORDER && (
                  <GenericTrackError
                    title={t('track.no-order.title')}
                    onClose={reset}
                    className="xl:[&>h3]:text-2xl xl:[&>svg]:w-24 xl:[&>svg]:h-24 xl:[&>p]:text-xl"
                    showSubstituteMeals
                    mode="restaurant"
                    trackLocationId={selectedTrackLocation.id}
                    date={date}
                    userId={trackError.user_id}
                    shift={selectedShift}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="basis-[18rem]">
          <div className="ml-auto flex flex-col gap-12">
            <div className="relative w-full aspect-square bg-gray-50 rounded-md">
              {barcodeScannerDisabled && (
                <div className="absolute z-10 bg-black/30 backdrop-blur-sm inset-0" />
              )}
              {!QRScannerOpen && (
                <QrCode className="w-32 h-32 opacity-85 mx-auto mt-14" />
              )}
              {QRScannerOpen && (
                <Scanner
                  paused={barcodeScannerDisabled || isPaused}
                  allowMultiple
                  scanDelay={1000}
                  key={`${selectedShift}-${restaurant_id}-${date}-${selectedTrackLocation.id}`}
                  onScan={(result) => {
                    const firstResult = result[0];
                    if (!firstResult) return;
                    const code = firstResult.rawValue;
                    if (!code) return;
                    mutate({
                      restaurant_id,
                      qr_code: code,
                      shift: selectedShift,
                      restaurant_track_location_id: selectedTrackLocation.id,
                      date: date,
                    });
                  }}
                  onError={(err) => console.log(err)}
                />
              )}
              <Button
                onClick={QRScannerOpen ? closeScanner : openScanner}
                disabled={QRScannerLoading}
                className="xl:h-11 xl:text-xl xl:px-5 absolute z-20 bottom-2 right-1/2 translate-x-1/2"
              >
                {QRScannerOpen
                  ? t('track.hide-qr-scanner')
                  : t('track.show-qr-scanner')}
              </Button>
            </div>
            <div>
              <form
                onSubmit={handleSubmit}
                className="flex flex-wrap gap-4 items-center"
              >
                <div className="flex  flex-col gap-2">
                  <Label htmlFor={uid + '-user-id'} className="xl:text-xl ">
                    {t('track.user-id')}
                  </Label>
                  <Input
                    id={uid + '-user-id'}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    className="xl:h-11"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    placeholder={t('track.user-id-placeholder')}
                  />
                </div>
                <Button
                  disabled={!isValid}
                  type="submit"
                  className="self-end xl:h-11 xl:text-xl xl:px-5"
                >
                  {t('track.send')}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestaurantTrack;
