import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetRestaurantTrackLocationsQuery,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { CreateRestaurantTrackLocationModal } from '@/components/restaurant/track-locations/create-restaurant-track-location-modal';
import { EditRestaurantTrackLocationModal } from '@/components/restaurant/track-locations/edit-restaurant-track-location-modal';

import { columns } from '@/config/restaurant/track-locations';

const RestaurantTrackLocations = () => {
  const { t } = useTranslation();
  const { trackLocationId } = useParams();
  const restaurant = useAuthUserRestaurant();

  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const { data, isLoading } = useGetRestaurantTrackLocationsQuery({
    restaurant_id: restaurant.id,
  });
  return (
    <>
      <Helmet>
        <title>{t('track-location.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('track-location.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to={`/restaurant/track-locations/create`}
              className={buttonVariants({ variant: 'default' })}
            >
              {t('track-location.create')}
            </Link>
          </div>
          <DataTable
            columns={memoizedColumns}
            data={data}
            isLoading={isLoading}
          />
        </div>
      </div>
      <CreateRestaurantTrackLocationModal />
      {Boolean(trackLocationId) && (
        <EditRestaurantTrackLocationModal key={trackLocationId} />
      )}
    </>
  );
};

export default RestaurantTrackLocations;
