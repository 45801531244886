import clsx from 'clsx';
import { ReactNode } from 'react';
import { OctagonAlert, X } from 'lucide-react';

import {
  Card,
  CardDescription,
  CardTitle,
} from '@/components/shared/shadcn-ui/card';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { ChooseSubstituteMeal as CompanyChooseSubstituteMeal } from '../company/track/choose-substitute-meal';
import { ChooseSubstituteMeal as RestaurantChooseSubstituteMeal } from '../restaurant/track/choose-substitute-meal';

interface GenericTrackErrorProps {
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  onClose?: () => void;
  shift?: RestaurantShift;
  showSubstituteMeals?: boolean;
  trackLocationId?: number;
  mode?: 'restaurant' | 'company';
  userId?: number;
  date?: string;
}

export const GenericTrackError = ({
  title,
  description,
  onClose,
  className,
  shift,
  showSubstituteMeals,
  trackLocationId,
  mode = 'company',
  date,
  userId,
}: GenericTrackErrorProps) => {
  return (
    <Card className={clsx('p-5 flex flex-col gap-4 relative', className)}>
      <OctagonAlert className="h-16 w-16 text-black" />
      {onClose && (
        <button
          type="button"
          onClick={onClose}
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <X className="h-4 w-4" />
        </button>
      )}

      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      {showSubstituteMeals &&
        !!shift &&
        !!date &&
        !!userId &&
        !!trackLocationId && (
          <>
            {mode === 'company' && (
              <CompanyChooseSubstituteMeal
                shift={shift}
                date={date}
                userId={userId}
                companyTrackLocationId={trackLocationId}
                onOrderCreated={onClose}
              />
            )}
            {mode === 'restaurant' && (
              <RestaurantChooseSubstituteMeal
                shift={shift}
                date={date}
                userId={userId}
                restaurantTrackLocationId={trackLocationId}
                onOrderCreated={onClose}
              />
            )}
          </>
        )}
    </Card>
  );
};
