import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useMemo, useState } from 'react';
import {
  ValidationError,
  useGetMenuTypesQuery,
  useAuthUserRestaurant,
  useEditRestaurantCompanyLocationMutation,
  useGetSingleRestaurantCompanyLocationSuspenseQuery,
} from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { CompanyLocationDetails } from '@/components/restaurant/companies/company-location-details';
import { CompanyLocationAddress } from '@/components/restaurant/companies/company-location-address';
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from '@/components/shared/shadcn-ui/tabs';

import { useChangedObject } from '@/hooks/use-changed-object';

import { isNotEmpty } from '@/utils/helpers';
import { CompanyLocationFormData } from '@/types/companies';

interface EditRestaurantCompanyLocationProps {
  locationId: string;
  companyId: string;
  closeModal: () => unknown;
}

export const EditRestaurantCompanyLocationForm = ({
  locationId,
  companyId,
  closeModal,
}: EditRestaurantCompanyLocationProps) => {
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();

  const { data: location } = useGetSingleRestaurantCompanyLocationSuspenseQuery(
    {
      restaurant_id: restaurant.id,
      company_id: +companyId,
      location_id: +locationId,
    },
  );

  const [formData, setFormData] = useState<CompanyLocationFormData>({
    name: location.name,
    shift_1: location.shift_1,
    shift_2: location.shift_2,
    shift_3: location.shift_3,
    location_type: location.location_type,
    company_track_location_id: location.company_track_location
      ? location.company_track_location.id
      : null,
    restaurant_track_location_id: location.restaurant_track_location
      ? location.restaurant_track_location.id
      : null,
    menu_type_id: location.menu_type ? location.menu_type.id : null,
    street: location.street || undefined,
    city: location.city || undefined,
    zip: location.zip || undefined,
    state: location.state || undefined,
    country: location.country || undefined,
  });

  const editRestaurantCompanyLocation =
    useEditRestaurantCompanyLocationMutation();

  const [isChanged, changedObj] = useChangedObject(formData, location, {
    customComparators: {
      menu_type_id: (a) => {
        return a == location.menu_type?.id;
      },
      company_track_location_id: (a) => {
        return a == location.company_track_location?.id;
      },
      restaurant_track_location_id: (a) => {
        return a == location.restaurant_track_location?.id;
      },
    },
  });

  const { data: menuTypes, isLoading: isLoadingMenuTypes } =
    useGetMenuTypesQuery(restaurant.id);

  const isValid = useMemo(() => {
    return (
      isNotEmpty(formData.name) &&
      isChanged &&
      (formData.location_type !== 'track' ||
        formData.company_track_location_id) &&
      (formData.location_type !== 'restaurant' ||
      formData.restaurant_track_location_id)
    );
  }, [
    formData.name,
    isChanged,
    formData.location_type,
    formData.company_track_location_id,
    formData.restaurant_track_location_id,
  ]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await editRestaurantCompanyLocation.mutateAsync({
        restaurant_id: restaurant.id,
        company_id: +companyId,
        location_id: +location.id,
        ...changedObj,
        menu_type_id: changedObj.menu_type_id
          ? changedObj.menu_type_id
          : undefined,
      });
      closeModal();
      toast.success(t('company-locations.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company-locations.edit-error-msg'));
    }
  };

  return (
    <Tabs defaultValue="details" className="flex flex-col items-start gap-5">
      <form onSubmit={handleSubmit} className="w-full">
        <TabsList className="w-full">
          <TabsTrigger value="details" className="flex-1 w-full">
            {t('common.details')}
          </TabsTrigger>
          <TabsTrigger value="address" className="flex-1 w-full">
            {t('common.address')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="details">
          <CompanyLocationDetails
            menuTypes={menuTypes || []}
            isLoadingMenuTypes={isLoadingMenuTypes}
            formData={formData}
            setFormData={setFormData}
          />
        </TabsContent>
        <TabsContent value="address" className="w-full">
          <CompanyLocationAddress
            formData={formData}
            setFormData={setFormData}
          />
        </TabsContent>
        <DialogFooter className="mt-2">
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={editRestaurantCompanyLocation.isPending}
          >
            {t('common.save-edit')}
          </Button>
        </DialogFooter>
      </form>
    </Tabs>
  );
};
