import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import { ShortRestaurantLocation } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUserRestaurant,
  useCreateCompanyLocationGroupMutation,
  useGetAllRestaurantCompaniesQuery,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { DataTable } from '@/components/shared/data-table';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { SelectCompanyLocations } from '@/components/shared/select-company-locations';

import { locationsColumns } from '@/config/restaurant/company-groups';

import { isNotEmpty } from '@/utils/helpers';

interface CreateRestaurantCompanyGroupsFormProps {
  closeModal: () => unknown;
}

export const CreateRestaurantCompanyGroupsForm = ({
  closeModal,
}: CreateRestaurantCompanyGroupsFormProps) => {
  const { t } = useTranslation();

  const memoizedColumns = useMemo(() => {
    return locationsColumns(t);
  }, [t]);

  const [groupName, setGroupName] = useState<string>('');
  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);

  const uid = useId();

  const restaurant = useAuthUserRestaurant();
  const createRestaurantCompanyGroup = useCreateCompanyLocationGroupMutation();

  const {
    data: companiesAndGroups,
    isLoading: isLoadingCompanies,
    isSuccess: isSuccessCompanies,
  } = useGetAllRestaurantCompaniesQuery(restaurant.id);

  const isValid = useMemo(() => {
    return isNotEmpty(groupName) && companyLocations.length > 0;
  }, [groupName, companyLocations]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantCompanyGroup.mutateAsync({
        restaurant_id: restaurant.id,
        name: groupName,
        location_ids: companyLocations.length
          ? companyLocations.map(({ id }) => id)
          : [],
      });
      closeModal();
      setGroupName('');
      setCompanyLocations([]);
      toast.success(t('company.groups.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company.groups.create-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            id={uid + '-name'}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder={t('common.name-placeholder')}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-locations'}>
            {t('company-locations.title')}
          </Label>
          <div>
            <SelectCompanyLocations
              showSelectAll={false}
              companies={isSuccessCompanies ? companiesAndGroups.companies : []}
              isLoading={isLoadingCompanies}
              selectedCompanyLocations={companyLocations}
              onUpdateCompanyLocations={setCompanyLocations}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label>{t('company.groups.choosen-locations')}</Label>
          <DataTable
            isLoading={isLoadingCompanies}
            columns={memoizedColumns}
            data={companyLocations}
            onDeleteTableRow={(tableRowId) => {
              setCompanyLocations((locations) =>
                locations.filter((location) => location.id !== tableRowId),
              );
            }}
          />
        </div>
      </div>

      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createRestaurantCompanyGroup.isPending}
        >
          {t('company.groups.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
