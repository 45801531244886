import { useMemo, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  RestaurantShift,
  ShortRestaurantLocation,
} from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  SHIFT,
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
  useGetSubstituteMealsReportQuery,
} from '@durma-soft/gros-sdk';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { Button } from '@/components/shared/shadcn-ui/button';
import { PrintButton } from '@/components/shared/print-button';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectCompanyLocations } from '@/components/shared/select-company-locations';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/shared/shadcn-ui/dropdown-menu';

import print from '@/lib/print/print';

import { columns } from '@/config/restaurant/reports/packages';

const SubstituteMealsReport = () => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns(t), [t]);

  const [shiftsDropdownOpen, setShiftsDropdownOpen] = useState(false);
  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);
  const [selectedShifts, setSelectedShifts] = useState<RestaurantShift[]>([
    SHIFT.FIRST,
    SHIFT.SECOND,
    SHIFT.THIRD,
  ]);

  const onUpdateCompanyLocations = (
    newCompanyLocations: ShortRestaurantLocation[],
  ) => {
    setCompanyLocations(newCompanyLocations);
  };

  const restaurant = useAuthUserRestaurant();

  const { from, to, setDateRange } = useDatePickerRangeState();
  const { data: companiesAndGroups, isLoading: isLoadingCompanies } =
    useGetAllRestaurantCompaniesQuery(restaurant.id);

  const { data: substituteMealOrders, isSuccess } =
    useGetSubstituteMealsReportQuery({
      restaurant_id: restaurant.id,
      start_date: from,
      end_date: to,
      company_location_ids: companyLocations.length
        ? companyLocations.map(({ id }) => id)
        : [],
    });

  return (
    <>
      <Helmet>
        <title>{t('reports.substitute-meals.title')}</title>
      </Helmet>
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.substitute-meals.title')}
        </h1>
      </div>
      <div className="flex flex-wrap justify-between gap-5 mt-5 mb-5">
        <div className="flex flex-col xl:flex-row gap-5">
          <DatePickerWithRange
            date={{
              from: new Date(from),
              to: new Date(to),
            }}
            setDate={([from, to]) => {
              setDateRange({ from, to });
            }}
          />
          <DropdownMenu
            open={shiftsDropdownOpen}
            onOpenChange={setShiftsDropdownOpen}
          >
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                className="gap-2 font-normal print:hidden flex justify-between w-[200px]"
              >
                {t('common.choose-shifts')}
                <span>
                  <ChevronDown size={16} />
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {['1', '2', '3'].map((shift) => (
                <DropdownMenuCheckboxItem
                  key={shift}
                  checked={selectedShifts.includes(shift as RestaurantShift)}
                  onSelect={(event) => {
                    event.preventDefault();
                    setSelectedShifts((prev) =>
                      prev.includes(shift as RestaurantShift)
                        ? prev.filter((s) => s !== shift)
                        : [...prev, shift as RestaurantShift],
                    );
                  }}
                >
                  {shift}. {t('menu.shift')}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <SelectCompanyLocations
            companies={companiesAndGroups?.companies || []}
            groups={companiesAndGroups?.groups || []}
            isLoading={isLoadingCompanies}
            selectedCompanyLocations={companyLocations}
            onUpdateCompanyLocations={onUpdateCompanyLocations}
          />
        </div>
        <div>
          <PrintButton
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <h1>{t('reports.substitute-meals.title')} </h1>
                      <p>
                        {format(from, 'dd.MM.yyyy')}
                        {!isEqual(from, to) && ` - ${format(to, 'dd.MM.yyyy')}`}
                      </p>
                    </div>
                    <div>
                      {isSuccess &&
                        substituteMealOrders.map((order) => {
                          return (
                            <div key={order.id} className="flex flex-col gap-4">
                              <h3 className="text-xl font-medium">
                                {order.name}
                              </h3>
                              <DataTableHTML
                                columnVisibility={{
                                  shift_1: selectedShifts.includes(SHIFT.FIRST),
                                  shift_2: selectedShifts.includes(
                                    SHIFT.SECOND,
                                  ),
                                  shift_3: selectedShifts.includes(SHIFT.THIRD),
                                }}
                                columns={memoizedColumns}
                                data={order.foods}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {isSuccess &&
          !substituteMealOrders.length &&
          companyLocations.length !== 0 && (
            <p className="self-center">{t('reports.no-data')}</p>
          )}

        {companyLocations.length === 0 && (
          <p className="self-center">{t('reports.orders.choose-location')}</p>
        )}
        {isSuccess && (
          <TableSizeSyncProvider>
            {substituteMealOrders.map((order, index) => {
              return (
                <div key={order.id} className="flex flex-col gap-4">
                  <h3 className="text-xl font-medium">{order.name}</h3>
                  <DataTable
                    isSyncReference={index === 0}
                    columnVisibility={{
                      shift_1: selectedShifts.includes(SHIFT.FIRST),
                      shift_2: selectedShifts.includes(SHIFT.SECOND),
                      shift_3: selectedShifts.includes(SHIFT.THIRD),
                    }}
                    columns={memoizedColumns}
                    data={order.foods}
                  />
                </div>
              );
            })}
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default SubstituteMealsReport;
