import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { isNotEmpty } from '@/utils/helpers';
import {
  useAuthUserRestaurant,
  useCreateRestaurantTrackLocationMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';
import { FormEvent, useId, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

interface RestaurantTrackLocationFormData {
  name: string;
  street: string;
  city: string;
  zip: string;
  state: string;
  country: string;
}

interface CreateRestaurantTrackLocationProps {
  closeModal: () => unknown;
}

export const CreateRestaurantTrackLocationForm = ({
  closeModal,
}: CreateRestaurantTrackLocationProps) => {
  const uid = useId();

  const { t } = useTranslation();

  const [formData, setFormData] = useState<RestaurantTrackLocationFormData>({
    name: '',
    street: '',
    city: '',
    zip: '',
    state: '',
    country: '',
  });

  const restaurant = useAuthUserRestaurant();

  const createRestaurantTrackLocation =
    useCreateRestaurantTrackLocationMutation();

  const isValid = useMemo(() => {
    return isNotEmpty(formData.name);
  }, [formData.name]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantTrackLocation.mutateAsync({
        restaurant_id: restaurant.id,
        name: formData.name,
        street: formData.street || undefined,
        city: formData.city || undefined,
        zip: formData.zip || undefined,
        state: formData.state || undefined,
        country: formData.country || undefined,
      });
      closeModal();
      toast.success(t('track-location.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('track-location.create-error-msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-6">
      <div className="grid gap-4">
        {/* Name */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            required
            id={uid + '-name'}
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder={t('common.name-placeholder')}
          />
        </div>
        {/* Street */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-street'}>
            {t('common.address-details.street')}
          </Label>
          <Input
            id={uid + '-street'}
            value={formData.street || ''}
            onChange={(e) =>
              setFormData({ ...formData, street: e.target.value })
            }
            placeholder={t('common.address-details.street-placeholder')}
          />
        </div>
        {/* City */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-city'}>
            {t('common.address-details.city')}
          </Label>
          <Input
            id={uid + '-city'}
            value={formData.city || ''}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            placeholder={t('common.address-details.city-placeholder')}
          />
        </div>
        {/* ZIP */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-zip'}>
            {t('common.address-details.zip')}
          </Label>
          <Input
            id={uid + '-zip'}
            value={formData.zip || ''}
            onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
            placeholder={t('common.address-details.zip-placeholder')}
          />
        </div>
        {/* State */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-state'}>
            {t('common.address-details.state')}
          </Label>
          <Input
            id={uid + '-state'}
            value={formData.state || ''}
            onChange={(e) =>
              setFormData({ ...formData, state: e.target.value })
            }
            placeholder={t('common.address-details.state-placeholder')}
          />
        </div>
        {/* Country */}
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-country'}>
            {t('common.address-details.country')}
          </Label>
          <Input
            id={uid + '-country'}
            value={formData.country || ''}
            onChange={(e) =>
              setFormData({ ...formData, country: e.target.value })
            }
            placeholder={t('common.address-details.country-placeholder')}
          />
        </div>
      </div>
      <DialogFooter>
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createRestaurantTrackLocation.isPending}
        >
          {t('track-location.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
