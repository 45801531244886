import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetSingleRestaurantTrackLocationQuery,
} from '@durma-soft/gros-sdk';

import { Alert } from '@/components/shared/alert';
import { Modal } from '@/components/shared/modal';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantTrackLocationForm } from '@/components/restaurant/track-locations/edit-restaurant-track-location-form';

export const EditRestaurantTrackLocationModal = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { trackLocationId } = useParams();

  if (!trackLocationId) {
    throw new Error(
      'Track location ID is required for editing its information!',
    );
  }

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission =
    restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE &&
    restaurant.role !== RESTAURANT_USER_ROLE.TRACK_EMPLOYEE;

  const {
    data: trackLocation,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleRestaurantTrackLocationQuery({
    restaurant_id: restaurant.id,
    track_location_id: +trackLocationId,
  });

  const closeModal = () =>
    navigate(`/restaurant/track-locations`, { replace: true });

  const isModalOpen = Boolean(
    useMatch({
      path: '/restaurant/track-locations/edit/:trackLocationId',
    }),
  );

  return (
    <Modal
      key={trackLocationId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('track-location.edit')}
      description={t('track-location.edit-details')}
    >
      {isLoading && <ModalFormSkeleton />}
      {!hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.edit-not-allowed')}
          description={t('common.edit-not-allowed-description')}
        />
      )}
      {isError && hasEditPermission && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && hasEditPermission && (
        <EditRestaurantTrackLocationForm
          trackLocation={trackLocation}
          closeModal={closeModal}
        />
      )}
    </Modal>
  );
};
