import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  LocationType,
  useAuthUserCompany,
  useEditCompanyLocationMutation,
  useGetSingleCompanyLocationSuspenseQuery,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { TrackLocations } from '@/components/company/locations/track-locations';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/components/shared/shadcn-ui/radio-group';

import { useChangedObject } from '@/hooks/use-changed-object';
import { locationDeliveryTypes } from '@/config/global';

import { isNotEmpty } from '@/utils/helpers';
import { SingleCompanyLocationFormData } from '@/types/companies';

interface EditCompanyLocationProps {
  locationId: string;
  closeModal: () => unknown;
}

export const EditCompanyLocationForm = ({
  locationId,
  closeModal,
}: EditCompanyLocationProps) => {
  const uid = useId();
  const { t } = useTranslation();
  const company = useAuthUserCompany();

  const { data: location } = useGetSingleCompanyLocationSuspenseQuery({
    company_id: company.id,
    location_id: +locationId,
  });

  const [formData, setFormData] = useState<SingleCompanyLocationFormData>({
    name: location.name,
    street: location.street || undefined,
    city: location.city || undefined,
    zip: location.zip || undefined,
    state: location.state || undefined,
    country: location.country || undefined,
    location_type: location.location_type,
    company_track_location_id: location.company_track_location
      ? location.company_track_location.id
      : null,
    restaurant_track_location_id: location.restaurant_track_location
      ? location.restaurant_track_location.id
      : null,
  });

  const editCompanyLocation = useEditCompanyLocationMutation();

  const [isChanged, changedObj] = useChangedObject(formData, location, {
    customComparators: {
      company_track_location_id: (a) => {
        return a === location.company_track_location?.id;
      },
      restaurant_track_location_id: (a) => {
        return a === location.restaurant_track_location?.id;
      },
    },
  });

  const isValid = useMemo(() => {
    return isNotEmpty(formData.name) && isChanged;
  }, [formData.name, isChanged]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await editCompanyLocation.mutateAsync({
        company_id: company.id,
        location_id: Number(location.id),
        ...changedObj,
        location_type: formData.location_type,
        company_track_location_id: Number(formData.company_track_location_id),
      });
      closeModal();
      toast.success(t('company-locations.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company-locations.edit-error-msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        <div className="grid gap-4 py-4">
          {/* Name */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
            <Input
              required
              id={uid + '-name'}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder={t('common.name-placeholder')}
            />
          </div>
          {/* Street */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-street'}>
              {t('common.address-details.street')}
            </Label>
            <Input
              id={uid + '-street'}
              value={formData.street || ''}
              onChange={(e) =>
                setFormData({ ...formData, street: e.target.value })
              }
              placeholder={t('common.address-details.street-placeholder')}
            />
          </div>
          {/* City */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-city'}>
              {t('common.address-details.city')}
            </Label>
            <Input
              id={uid + '-city'}
              value={formData.city || ''}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              placeholder={t('common.address-details.city-placeholder')}
            />
          </div>
          {/* ZIP */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-zip'}>
              {t('common.address-details.zip')}
            </Label>
            <Input
              id={uid + '-zip'}
              value={formData.zip || ''}
              onChange={(e) =>
                setFormData({ ...formData, zip: e.target.value })
              }
              placeholder={t('common.address-details.zip-placeholder')}
            />
          </div>
          {/* State */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-state'}>
              {t('common.address-details.state')}
            </Label>
            <Input
              id={uid + '-state'}
              value={formData.state || ''}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
              placeholder={t('common.address-details.state-placeholder')}
            />
          </div>
          {/* Country */}
          <div className="flex flex-col gap-2">
            <Label htmlFor={uid + '-country'}>
              {t('common.address-details.country')}
            </Label>
            <Input
              id={uid + '-country'}
              value={formData.country || ''}
              onChange={(e) =>
                setFormData({ ...formData, country: e.target.value })
              }
              placeholder={t('common.address-details.country-placeholder')}
            />
          </div>
          {/* Location type */}
          <div className="flex flex-col gap-2">
            <Label>{t('common.location-type')}</Label>
            <RadioGroup
              value={formData.location_type || 'direct'}
              onValueChange={(e) => {
                if ((e as LocationType) === 'direct') {
                  setFormData((prevData) => {
                    return { ...prevData, company_track_location_id: null };
                  });
                }
                setFormData((prevData) => {
                  return { ...prevData, location_type: e as LocationType };
                });
              }}
            >
              {locationDeliveryTypes(t)?.map(({ value, label }) => (
                <div key={value} className="flex items-center space-x-2">
                  <RadioGroupItem value={value} id={uid + value} />
                  <Label htmlFor={uid + value}>{label}</Label>
                </div>
              ))}
            </RadioGroup>
          </div>
          {formData.location_type === 'track' && (
            <TrackLocations
              formData={formData}
              setCompanyTrackLocationId={(newId) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  company_track_location_id: newId,
                }));
              }}
            />
          )}
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={editCompanyLocation.isPending}
        >
          {t('company-locations.edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
