import { ReceiptText } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { LocationType } from '@durma-soft/gros-sdk';
import { NumberOfOrdersPerEmployeeReportUser } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { Link } from '@/components/shared/link';

export const columns = (
  t: TType,
  locationType: LocationType,
): ColumnDef<NumberOfOrdersPerEmployeeReportUser>[] => [
  {
    header: 'ID',
    cell: (cellData) => {
      return <>{cellData.row.original.id}</>;
    },
  },
  {
    accessorKey: 'first_name',
    header: t('common.first-name'),
  },
  {
    accessorKey: 'last_name',
    header: t('common.last-name'),
  },
  {
    header: t('shifts.first'),
    cell: (cellData) => {
      const { shift_1_ordered, shift_1_picked_up } = cellData.row.original;
      if (locationType === 'direct') return shift_1_ordered;
      return `${shift_1_ordered} / ${shift_1_picked_up}`;
    },
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      const totalOrdered = rows.reduce((total, row) => {
        return total + row.original.shift_1_ordered;
      }, 0);
      if (locationType === 'direct') return totalOrdered;
      const totalPickedUp = rows.reduce((total, row) => {
        return total + row.original.shift_1_picked_up;
      }, 0);
      return `${totalOrdered} / ${totalPickedUp}`;
    },
  },
  {
    header: t('shifts.second'),
    cell: (cellData) => {
      const { shift_2_ordered, shift_2_picked_up } = cellData.row.original;
      if (locationType === 'direct') return shift_2_ordered;
      return `${shift_2_ordered} / ${shift_2_picked_up}`;
    },
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      const totalOrdered = rows.reduce((total, row) => {
        return total + row.original.shift_2_ordered;
      }, 0);
      if (locationType === 'direct') return totalOrdered;
      const totalPickedUp = rows.reduce((total, row) => {
        return total + row.original.shift_2_picked_up;
      }, 0);
      return `${totalOrdered} / ${totalPickedUp}`;
    },
  },
  {
    header: t('shifts.third'),
    cell: (cellData) => {
      const { shift_3_ordered, shift_3_picked_up } = cellData.row.original;
      if (locationType === 'direct') return shift_3_ordered;
      return `${shift_3_ordered} / ${shift_3_picked_up}`;
    },
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      const totalOrdered = rows.reduce((total, row) => {
        return total + row.original.shift_3_ordered;
      }, 0);
      if (locationType === 'direct') return totalOrdered;
      const totalPickedUp = rows.reduce((total, row) => {
        return total + row.original.shift_3_picked_up;
      }, 0);
      return `${totalOrdered} / ${totalPickedUp}`;
    },
  },
  {
    header: t('common.total'),
    cell: (cellData) => {
      const {
        shift_1_ordered,
        shift_2_ordered,
        shift_3_ordered,
        shift_1_picked_up,
        shift_2_picked_up,
        shift_3_picked_up,
      } = cellData.row.original;
      const totalOrdered = shift_1_ordered + shift_2_ordered + shift_3_ordered;
      if (locationType === 'direct') return totalOrdered;
      const totalPickedUp =
        shift_1_picked_up + shift_2_picked_up + shift_3_picked_up;
      return `${totalOrdered} / ${totalPickedUp}`;
    },
    footer: ({ table }) => {
      const { rows } = table.getFilteredRowModel();
      const totalOrdered = rows.reduce((total, row) => {
        const { shift_1_ordered, shift_2_ordered, shift_3_ordered } =
          row.original;
        return total + shift_1_ordered + shift_2_ordered + shift_3_ordered;
      }, 0);
      if (locationType === 'direct') return totalOrdered;
      const totalPickedUp = rows.reduce((total, row) => {
        const { shift_1_picked_up, shift_2_picked_up, shift_3_picked_up } =
          row.original;
        return (
          total + shift_1_picked_up + shift_2_picked_up + shift_3_picked_up
        );
      }, 0);
      return `${totalOrdered} / ${totalPickedUp}`;
    },
  },
  {
    accessorKey: 'details',
    header: '',
    cell: (cellData) => {
      return (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                to={`/company/reports/customer-orders/single-employee/${cellData.row.original.id}`}
                className="flex items-center justify-center text-gray-700 "
              >
                <ReceiptText className="w-5 h-5" />
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              {t('reports.orders-by-employee.details')}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
  },
];
