import { useEffect, useRef, useState } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  availableUnits,
  convertUnits,
  PrimaryUnit,
  Unit,
} from '@durma-soft/gros-sdk';

import { ShortRestaurantIngredientWithQuantity } from '@/types/foods';

import { TType } from '@/types/general';
import { Input } from '@/components/shared/shadcn-ui/input';
import { InputAddon } from '@/components/shared/input-addon';

interface CustomQuantityCellProps {
  t: TType;
  cellProps: CellContext<ShortRestaurantIngredientWithQuantity, unknown>;
}

const CustomQuantityCell = ({ t, cellProps }: CustomQuantityCellProps) => {
  const [selectedUnit, setSelectedUnit] = useState<Unit>(
    (cellProps.row.original.preferred_display_unit as Unit) ||
      (cellProps.row.original.unit as Unit),
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    const handler = (e: MouseEvent) => {
      e.preventDefault();
      return false;
    };

    const input = inputRef.current;
    input.addEventListener('wheel', handler, { passive: false });
    return () => {
      input.removeEventListener('wheel', handler);
    };
  }, []);

  const id = cellProps.column.id;
  const index = cellProps.row.index;
  const onUpdateCellValue = cellProps.table.options.onUpdateCellValue;

  const quantity = cellProps.row.original.quantity;
  const primaryUnit = cellProps.row.original.unit as PrimaryUnit;

  const units = availableUnits[primaryUnit];

  const updateValue = (value: number | string) => {
    const quantityInPrimaryUnit = value === '' ? '' : value;
    if (onUpdateCellValue) {
      onUpdateCellValue(index, id, String(quantityInPrimaryUnit));
    }
  };

  return (
    <div className="flex items-stretch w-full">
      <Input
        ref={inputRef}
        type="number"
        value={
          (quantity as unknown) === ''
            ? ''
            : convertUnits({
                from: primaryUnit,
                to: selectedUnit,
                quantity: quantity as number,
              })
        }
        inputMode="numeric"
        onChange={(e) => {
          updateValue(
            e.target.value === ''
              ? ''
              : convertUnits({
                  from: selectedUnit,
                  to: primaryUnit,
                  quantity: Number(e.target.value),
                }),
          );
        }}
        style={{
          appearance: 'textfield',
        }}
        className="flex-1 lowercase border-r-0 rounded-r-none"
      />
      <InputAddon>
        <select
          value={selectedUnit}
          onChange={(e) => {
            setSelectedUnit(e.target.value as Unit);
          }}
          className="focus:outline-none bg-transparent"
        >
          {units.map((unit) => (
            <option key={unit} value={unit}>
              {t(`common.measure-unit-short.${unit}`)}
            </option>
          ))}
        </select>
      </InputAddon>
    </div>
  );
};

export const columns = (
  t: TType,
): ColumnDef<ShortRestaurantIngredientWithQuantity>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'quantity',
    header: t('common.quantity'),
    cell: (props) => <CustomQuantityCell t={t} cellProps={props} />,
  },
];

export const inputTypes = (t: TType) => [
  {
    value: 'add',
    name: t('ingredient.input-type.add'),
  },
  {
    value: 'subtract',
    name: t('ingredient.input-type.subtract'),
  },
  {
    value: 'new_state',
    name: t('ingredient.input-type.new-state'),
  },
];
