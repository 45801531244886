import { useTranslation } from 'react-i18next';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';

import {
  Tabs,
  TabsList,
  TabsTrigger,
} from '@/components/shared/shadcn-ui/tabs';

interface ManualOrdersShiftsPickerProps {
  selectedShift: RestaurantShift;
  availableShifts: RestaurantShift[];
  onShiftChange: (shift: RestaurantShift) => void;
  isActive?: boolean;
  activeOrders?: Partial<Record<RestaurantShift, number>>;
}

export const ManualOrdersShiftsPicker = ({
  selectedShift,
  availableShifts,
  onShiftChange,
  activeOrders = {},
}: ManualOrdersShiftsPickerProps) => {
  const { t } = useTranslation();
  return (
    <Tabs
      value={selectedShift}
      onValueChange={(value) => onShiftChange(value as RestaurantShift)}
    >
      <TabsList className="w-full justify-around">
        {availableShifts.map((shift) => (
          <TabsTrigger className="flex-1 lowercase" key={shift} value={shift}>
            {shift}. {t('menu.shift')}{' '}
            {activeOrders[shift] ? (
              <span className="inline-block ml-1 text-black/50">
                ({activeOrders[shift]})
              </span>
            ) : null}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};
