import { toast } from 'sonner';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useAuthUserRestaurant,
  useUpdateMenusActiveStatusMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { DatePicker } from '@/components/shared/date-picker';
import { MenusList } from '@/components/restaurant/menus/menus-list';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { CopyRestaurantMenuModal } from '@/components/restaurant/menus/copy-restaurant-menu-modal';
import { EditRestaurantMenuModal } from '@/components/restaurant/menus/edit-restaurant-menu-modal';
import { CreateRestaurantMenuModal } from '@/components/restaurant/menus/create-restaurant-menu-modal';

import { useDatePickerState } from '@/hooks/use-date-picker-state';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';
import { options } from '@/config/restaurant/menus';

const RestaurantMenus = () => {
  const { t } = useTranslation();
  const { menuId } = useParams();
  const [searchParams] = useSearchParams();

  const { date, setDate, datePickerOpen, setDatePickerOpen } =
    useDatePickerState();

  const restaurant = useAuthUserRestaurant();

  const updateActiveMenusStatus = useUpdateMenusActiveStatusMutation();

  return (
    <>
      <Helmet>
        <title>{t('menu.title')}</title>
      </Helmet>
      <div>
        <div>
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('menu.title')}
          </h1>
        </div>
        <div className="flex justify-between mt-5 mb-12">
          <div>
            <DatePicker
              open={datePickerOpen}
              onOpenChange={setDatePickerOpen}
              date={new Date(date)}
              setDate={(newDate) => {
                if (!newDate) return;
                setDate(format(newDate, 'yyyy-MM-dd'));
                setDatePickerOpen(false);
              }}
            />
          </div>
          <div className="flex gap-4">
            <Select
              value="choose"
              disabled={updateActiveMenusStatus.isPending}
              onValueChange={async (value) => {
                try {
                  await updateActiveMenusStatus.mutateAsync({
                    restaurant_id: restaurant.id,
                    date,
                    // to discuss
                    is_active: value === 'activate' ? true : false,
                  });
                  toast.success(t('menu.activate-all-success-msg'));
                } catch (error) {
                  if (error instanceof ValidationError) {
                    return toast.error(error.message);
                  }
                  toast.error(t('menu.activate-all-error-msg'));
                }
              }}
            >
              <SelectTrigger className="font-medium max-w-max">
                <SelectValue placeholder={t('menu.options')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem className="!hidden" value={'choose'}>
                  {t('menu.options')}
                </SelectItem>
                {options(t).map(({ id, label, value }) => (
                  <SelectItem key={id} value={value || ''}>
                    {label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Link
              to={`copy?${searchParams.toString()}`}
              className={buttonVariants({ variant: 'outline' })}
            >
              {t('menu.copy.title')}
            </Link>
            <Link
              to={`create?date=${date}`}
              className={buttonVariants({ variant: 'default' })}
            >
              {t('menu.create')}
            </Link>
          </div>
        </div>

        <div>
          <MenusList date={date} />
        </div>
      </div>
      <CopyRestaurantMenuModal />
      <CreateRestaurantMenuModal date={date} />
      {Boolean(menuId) && <EditRestaurantMenuModal key={menuId} date={date} />}
    </>
  );
};

export default RestaurantMenus;
