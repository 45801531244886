import { useTranslation } from 'react-i18next';

import { Button } from '@/components/shared/shadcn-ui/button';
import { useOrder } from '@/components/restaurant/manual-orders/manual-orders-provider';

export const ManualOrdersStatus = () => {
  const { t } = useTranslation();

  const {
    isActive,
    cancelOrder,
    ordersInProgress,
    totalOrders,
    cancelActiveOrder,
    confirmActiveOrder,
    isCancelOrderMutationPending,
    isOrderMutationPending,
    setIsActive,
  } = useOrder();

  return (
    <div className="sticky bottom-0 left-0 w-full p-5 border rounded-md border-border shadow bg-white">
      {isActive && totalOrders === 0 && (
        <p className="text-center">{t('manual-orders.status.make-an-order')}</p>
      )}
      {isActive && totalOrders > 0 && (
        <div className="space-y-3">
          <p className="text-center">
            {t('manual-orders.status.orders-and-shifts', {
              meals: totalOrders,
              shifts: Object.keys(ordersInProgress).length,
            })}
          </p>
          <div className="flex justify-center items-center gap-3">
            <Button
              onClick={cancelActiveOrder}
              disabled={isCancelOrderMutationPending}
              variant="secondary"
            >
              {t('manual-orders.status.cancel')}
            </Button>
            <Button
              isLoading={isOrderMutationPending}
              onClick={confirmActiveOrder}
              disabled={isOrderMutationPending}
              variant="default"
            >
              {t('manual-orders.status.confirm-order')}
            </Button>
          </div>
        </div>
      )}
      {!isActive && (
        <div className="space-y-3">
          <p className="text-center">
            {t('manual-orders.status.orders-total', { count: totalOrders })}
          </p>
          <div className="flex justify-center items-center gap-3">
            <Button
              isLoading={isCancelOrderMutationPending}
              onClick={cancelOrder}
              disabled={isCancelOrderMutationPending}
              variant="secondary"
            >
              {t('manual-orders.status.cancel-order')}
            </Button>
            <Button
              isLoading={isOrderMutationPending}
              onClick={() => setIsActive(true)}
              disabled={isOrderMutationPending || isCancelOrderMutationPending}
              variant="default"
            >
              {t('manual-orders.status.modify-order')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
