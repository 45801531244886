import { Navigate, Outlet } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';

export const UserIsNotRestaurantTrackEmployeeMiddleware = () => {
  const { role } = useAuthUserRestaurant();

  if (role === RESTAURANT_USER_ROLE.TRACK_EMPLOYEE) {
    return (
      <>
        <Navigate to="/restaurant/track" />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};
