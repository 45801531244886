import { useMemo, useState } from 'react';
import { RestaurantShift } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUserRestaurant,
  useGetRestaurantManualOrdersQuery,
} from '@durma-soft/gros-sdk';

import { OrderProvider } from '@/components/restaurant/manual-orders/manual-orders-provider';
import { ManualOrdersStatus } from '@/components/restaurant/manual-orders/manual-orders-status';
import { ManualOrdersMenuItem } from '@/components/restaurant/manual-orders/manual-orders-menu-item';
import { ManualOrdersShiftsPicker } from '@/components/restaurant/manual-orders/manual-orders-shifts-picker';

interface ManualOrdersContainerProps {
  companyLocationId: number;
  date: string;
}

export const ManualOrdersContainer = ({
  companyLocationId,
  date,
}: ManualOrdersContainerProps) => {
  const { id: restaurant_id } = useAuthUserRestaurant();
  const { data, isSuccess } = useGetRestaurantManualOrdersQuery({
    company_location_id: companyLocationId,
    date,
    restaurant_id,
  });

  if (!isSuccess)
    throw new Error(
      'No data in ManualOrdersContainer. Please wrap it in a parent',
    );

  const [selectedShift, setSelectedShift] = useState<RestaurantShift>(() => {
    if (data?.menus['1']) return '1';
    if (data?.menus['2']) return '2';
    if (data?.menus['3']) return '3';
    throw new Error(
      'No shifts exist. Please check this before rendering the OrderContainer component',
    );
  });

  const [currentOrders, setCurrentOrders] = useState(data.existing_orders);

  const [isActive, setIsActive] = useState(data.existing_orders.length === 0);

  const availableShifts = useMemo(() => {
    return Object.keys(data?.menus).map((shift) => shift as RestaurantShift);
  }, [data]);

  const activeOrdersForShiftsPicker: Partial<Record<RestaurantShift, number>> =
    useMemo(() => {
      if (!currentOrders.length) return {};
      const ordersObj: Record<RestaurantShift, number> = {
        '1': 0,
        '2': 0,
        '3': 0,
      };
      currentOrders.forEach(({ foods, shift }) => {
        foods.forEach(({ quantity }) => {
          ordersObj[shift] += quantity;
        });
      });

      return ordersObj;
    }, [currentOrders]);

  const currentShiftMenu = useMemo(() => {
    return data.menus[`${selectedShift}` as RestaurantShift]!;
  }, [data, selectedShift]);

  const cancelActiveOrder = () => {
    setCurrentOrders(data.existing_orders);
    setIsActive(data.existing_orders.length === 0);
  };

  return (
    <OrderProvider
      date={date}
      isActive={isActive}
      cancelActiveOrder={cancelActiveOrder}
      locationId={companyLocationId}
      ordersInProgress={currentOrders}
      selectedMenuId={currentShiftMenu?.menu_id}
      selectedShift={selectedShift}
      setIsActive={setIsActive}
      setOrdersInProgress={setCurrentOrders}
    >
      <div className="flex flex-col gap-6 py-6">
        {availableShifts.length > 1 && (
          <ManualOrdersShiftsPicker
            availableShifts={availableShifts}
            onShiftChange={setSelectedShift}
            selectedShift={selectedShift}
            isActive={isActive}
            activeOrders={activeOrdersForShiftsPicker}
          />
        )}
        <div className="grid grid-cols-2 2xl:grid-cols-3 gap-6">
          {currentShiftMenu.foods.map((food) => (
            <ManualOrdersMenuItem key={food.id} food={food} />
          ))}
        </div>
      </div>
      <ManualOrdersStatus />
    </OrderProvider>
  );
};
