import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetRestaurantManualOrdersQuery,
} from '@durma-soft/gros-sdk';

import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { ManualOrdersContainer } from '@/components/restaurant/manual-orders/manual-orders-container';

import { repeat } from '@/utils/helpers';

interface ManualOrdersProps {
  companyLocationId: number;
  date: string;
}

export const ManualOrders = ({
  companyLocationId,
  date,
}: ManualOrdersProps) => {
  const { t } = useTranslation();
  const { id: restaurant_id } = useAuthUserRestaurant();
  const { data, isSuccess, isError, isLoading } =
    useGetRestaurantManualOrdersQuery({
      company_location_id: companyLocationId,
      date,
      restaurant_id,
    });

  if (isLoading)
    return (
      <div className="py-5 grid grid-cols-2 2xl:grid-cols-3 gap-6">
        {repeat(18).map((_, i) => (
          <Skeleton key={i} className="w-full h-20" />
        ))}
      </div>
    );

  if (isError) return <p className="py-5">{t('manual-orders.error')}</p>;

  const hasAtLeastOneShift = isSuccess && Object.keys(data.menus).length > 0;

  if (isSuccess && hasAtLeastOneShift) {
    return (
      <ManualOrdersContainer
        companyLocationId={companyLocationId}
        date={date}
      />
    );
  }

  return null;
};
