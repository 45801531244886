import { Modal } from '@/components/shared/modal';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { CreateRestaurantTrackLocationForm } from '@/components/restaurant/track-locations/create-restaurant-track-location-form';

export const CreateRestaurantTrackLocationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () =>
    navigate('/restaurant/track-locations', { replace: true });

  const isModalOpen = Boolean(
    useMatch({
      path: '/restaurant/track-locations/create',
    }),
  );

  return (
    <Modal
      isModalOpen={Boolean(isModalOpen)}
      closeModal={closeModal}
      title={t('track-location.create')}
      description={t('track-location.create-details')}
      className="overflow-hidden flex flex-col"
    >
      <CreateRestaurantTrackLocationForm closeModal={closeModal} />
    </Modal>
  );
};
