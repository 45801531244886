import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetRestaurantTrackLocationsQuery,
} from '@durma-soft/gros-sdk';

import { Alert } from '@/components/shared/alert';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';
import { Spinner } from '@/components/shared/spinner';

import { CreateEmployeeFormData } from '@/types/employees';

interface Props {
  disabled?: boolean;
  formData: CreateEmployeeFormData;
  setTrackLocationId: (id: number | null) => void;
}

export const TrackLocationsSelect = ({
  disabled,
  formData,
  setTrackLocationId,
}: Props) => {
  const uid = useId();
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();

  const { data: trackLocations, isLoading } =
    useGetRestaurantTrackLocationsQuery({
      restaurant_id: restaurant.id,
    });

  if (isLoading) return <Spinner />;

  if (!isLoading && trackLocations?.length === 0)
    return (
      <Alert
        variant="destructive"
        title={t('common.no-results')}
        description={t('track-location.no-results-description')}
      />
    );

  return (
    <Select
      disabled={disabled}
      value={
        formData.primary_track_location_id
          ? `${formData.primary_track_location_id}`
          : undefined
      }
      onValueChange={(e) => {
        if (formData.role === `${RESTAURANT_USER_ROLE.TRACK_EMPLOYEE}`) {
          setTrackLocationId(+e);
        } else {
          setTrackLocationId(null);
        }
      }}
    >
      <SelectTrigger id={uid + '-track-location'} disabled={disabled}>
        <SelectValue placeholder={t('track-location.choose')} />
      </SelectTrigger>
      <SelectContent>
        {trackLocations &&
          trackLocations.map(({ id, name }) => (
            <SelectItem key={id} value={`${id}`}>
              {name}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};
