import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import {
  RestaurantManualOrder,
  RestaurantShift,
} from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUserRestaurant,
  useCreateRestaurantManualOrderMutation,
  useDeleteRestaurantManualOrdersMutation,
} from '@durma-soft/gros-sdk';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
} from 'react';
import { CreateRestaurantManualOrderMutationVariables } from '@durma-soft/gros-sdk/dist/hooks/mutations/useCreateRestaurantManualOrderMutation';

export interface OrderContextType {
  selectedMenuId: number;
  selectedShift: RestaurantShift;
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  totalOrders: number;
  ordersInProgress: RestaurantManualOrder[];
  addFoodToOrder: (foodId: number) => void;
  removeFoodFromOrder: (foodId: number) => void;
  getFoodQuantity: (foodId: number) => number;
  cancelActiveOrder: () => void;
  confirmActiveOrder: () => Promise<boolean>;
  cancelOrder: () => Promise<boolean>;
  isOrderMutationPending: boolean;
  isCancelOrderMutationPending: boolean;
  orderMutationVariables:
    | CreateRestaurantManualOrderMutationVariables
    | undefined;
}

export const OrderContext = createContext<OrderContextType>({
  selectedMenuId: 0,
  selectedShift: '1',
  isActive: false,
  setIsActive: () => {},
  totalOrders: 0,
  getFoodQuantity: () => 0,
  ordersInProgress: [],
  addFoodToOrder: () => {},
  removeFoodFromOrder: () => {},
  cancelActiveOrder: () => {},
  confirmActiveOrder: () => Promise.resolve(true),
  cancelOrder: () => Promise.resolve(true),
  isOrderMutationPending: false,
  isCancelOrderMutationPending: false,
  orderMutationVariables: undefined,
});

type OrderProviderProps = Omit<
  OrderContextType,
  | 'getFoodQuantity'
  | 'addFoodToOrder'
  | 'removeFoodFromOrder'
  | 'maxDailyOrdersReached'
  | 'totalOrders'
  | 'confirmActiveOrder'
  | 'isOrderMutationPending'
  | 'orderMutationVariables'
  | 'cancelOrder'
  | 'isCancelOrderMutationPending'
> & {
  children?: ReactNode;
  date: string;
  locationId: number;
  setOrdersInProgress: Dispatch<SetStateAction<RestaurantManualOrder[]>>;
};

export const OrderProvider = ({
  children,
  isActive,
  setIsActive,
  ordersInProgress,
  selectedMenuId,
  selectedShift,
  setOrdersInProgress,
  cancelActiveOrder,
  date,
  locationId,
}: OrderProviderProps) => {
  const { t } = useTranslation();
  const { id: restaurant_id } = useAuthUserRestaurant();

  const orderMutation = useCreateRestaurantManualOrderMutation();
  const cancelOrderMutation = useDeleteRestaurantManualOrdersMutation();

  const getFoodQuantity = (foodId: number) => {
    return ordersInProgress.reduce((acc, order) => {
      if (order.shift === selectedShift) {
        const food = order.foods.find(({ food_id }) => food_id === foodId);
        if (food) {
          return acc + food.quantity;
        }
      }
      return acc;
    }, 0);
  };

  const addFoodToOrder = (foodId: number) => {
    const existingOrderForCurrentMenuId = ordersInProgress.find(
      (order) => order.menu_id === selectedMenuId,
    );

    if (!existingOrderForCurrentMenuId) {
      return setOrdersInProgress((prevOrdersInProgress) => [
        ...prevOrdersInProgress,
        {
          menu_id: selectedMenuId,
          shift: selectedShift,
          foods: [{ food_id: foodId, quantity: 1 }],
        },
      ]);
    }

    const existingFood = existingOrderForCurrentMenuId.foods.find(
      ({ food_id }) => food_id === foodId,
    );

    if (existingFood) {
      return setOrdersInProgress((prevOrdersInProgress) =>
        prevOrdersInProgress.map((order) => {
          if (order.menu_id === selectedMenuId) {
            return {
              ...order,
              foods: order.foods.map((food) =>
                food.food_id === foodId
                  ? { ...food, quantity: food.quantity + 1 }
                  : food,
              ),
            };
          }
          return order;
        }),
      );
    }

    return setOrdersInProgress((prevOrdersInProgress) =>
      prevOrdersInProgress.map((order) =>
        order.menu_id === selectedMenuId
          ? {
              ...order,
              foods: [...order.foods, { food_id: foodId, quantity: 1 }],
            }
          : order,
      ),
    );
  };

  const removeFoodFromOrder = (foodId: number) => {
    const existingOrderForCurrentMenuId = ordersInProgress.find(
      (order) => order.menu_id === selectedMenuId,
    );

    if (!existingOrderForCurrentMenuId) {
      return;
    }

    const existingFood = existingOrderForCurrentMenuId.foods.find(
      ({ food_id }) => food_id === foodId,
    );

    if (!existingFood) {
      return;
    }

    if (existingFood.quantity === 1) {
      return setOrdersInProgress((prevOrdersInProgress) =>
        prevOrdersInProgress
          .map((order) =>
            order.menu_id === selectedMenuId
              ? {
                  ...order,
                  foods: order.foods.filter(
                    ({ food_id }) => food_id !== foodId,
                  ),
                }
              : order,
          )
          .filter((order) => order.foods.length > 0),
      );
    }

    return setOrdersInProgress((prevOrdersInProgress) =>
      prevOrdersInProgress.map((order) =>
        order.menu_id === selectedMenuId
          ? {
              ...order,
              foods: order.foods.map((food) =>
                food.food_id === foodId
                  ? { ...food, quantity: food.quantity - 1 }
                  : food,
              ),
            }
          : order,
      ),
    );
  };

  const totalOrders = useMemo(() => {
    if (!ordersInProgress?.length) return 0;
    return ordersInProgress.reduce((acc, order) => {
      return acc + order.foods.reduce((acc, { quantity }) => acc + quantity, 0);
    }, 0);
  }, [ordersInProgress]);

  const confirmActiveOrder = async () => {
    if (cancelOrderMutation.isPending || orderMutation.isPending) return false;
    try {
      await orderMutation.mutateAsync({
        date,
        restaurant_id,
        location_id: locationId,
        orders: ordersInProgress,
      });
      setIsActive(false);
      toast.success(t('manual-orders.status.order-created-notification'));
      return true;
    } catch (error) {
      return false;
    }
  };

  const cancelOrder = async () => {
    if (cancelOrderMutation.isPending || orderMutation.isPending) return false;
    try {
      await cancelOrderMutation.mutateAsync({
        date,
        company_location_id: locationId,
        restaurant_id,
      });
      setOrdersInProgress([]);
      setIsActive(true);
      toast.success(t('manual-orders.status.order-cancelled-notification'));
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <OrderContext.Provider
      value={{
        selectedMenuId,
        selectedShift,
        isActive,
        setIsActive,
        totalOrders,
        ordersInProgress,
        getFoodQuantity,
        addFoodToOrder,
        removeFoodFromOrder,
        cancelActiveOrder,
        confirmActiveOrder,
        orderMutationVariables: orderMutation.variables,
        isOrderMutationPending: orderMutation.isPending,
        isCancelOrderMutationPending: cancelOrderMutation.isPending,
        cancelOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
