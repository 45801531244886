import { createContext, ReactNode, useContext, useState } from 'react';
import {
  COMPANY_USER_ROLE,
  RESTAURANT_USER_ROLE,
  useAuthContext,
} from '@durma-soft/gros-sdk';

import { LOCAL_STORAGE_SIDEBAR_OPEN_KEY } from '@/config/global/storage';

type SidebarToggleContextType = [boolean, (value: boolean) => void];
const SidebarToggleContext = createContext<SidebarToggleContextType>([
  false,
  () => {},
]);

interface SidebarToggleProviderProps {
  children?: ReactNode;
}

const SidebarToggleProvider = ({ children }: SidebarToggleProviderProps) => {
  const { user } = useAuthContext();

  const isTrackEmployee =
    !!user &&
    ((!!user.company &&
      user.company.role === COMPANY_USER_ROLE.TRACK_EMPLOYEE) ||
      (!!user.restaurant &&
        user.restaurant.role === RESTAURANT_USER_ROLE.TRACK_EMPLOYEE));

  const [isSidebarOpen, setIsSidebarOpenState] = useState(() => {
    if (isTrackEmployee) return false;
    const storedValue = localStorage.getItem(LOCAL_STORAGE_SIDEBAR_OPEN_KEY);
    try {
      if (storedValue && JSON.parse(storedValue) === false) return false;
      return true;
    } catch {
      return true;
    }
  });

  const setIsSidebarOpen = (value: boolean) => {
    setIsSidebarOpenState(value);
    localStorage.setItem(LOCAL_STORAGE_SIDEBAR_OPEN_KEY, JSON.stringify(value));
  };

  return (
    <SidebarToggleContext.Provider value={[isSidebarOpen, setIsSidebarOpen]}>
      {children}
    </SidebarToggleContext.Provider>
  );
};

export const useSidebarToggle = () => useContext(SidebarToggleContext);

export default SidebarToggleProvider;
