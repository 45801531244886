import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import { UniqueIdentifier } from '@dnd-kit/core';
import { CSSProperties, Dispatch, SetStateAction, useRef } from 'react';
import {
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  ColumnDef,
  flexRender,
  useReactTable,
  getCoreRowModel,
  RowData,
  Row,
  getExpandedRowModel,
  ExpandedState,
} from '@tanstack/react-table';

import { useTableSizeSync } from '@/providers/TableSizeSyncProvider';

import { Button } from '@/components/shared/shadcn-ui/button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/shared/shadcn-ui/select';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/shared/shadcn-ui/table';

import { nextTick, repeat } from '@/utils/helpers';
import { TType } from '@/types/general';

const tablePageSizeOptions = (t: TType) => [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 10000, label: t('table.all') },
];

const DraggableRow = ({ row }: { row: Row<unknown> }) => {
  const rowId = `${row.id}`;
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: rowId,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      key={rowId}
      id={rowId}
      data-state={row.getIsSelected() && 'selected'}
    >
      {row.getVisibleCells().map((cell) => (
        <TableCell
          id={cell.id}
          key={cell.id}
          style={{ width: cell.column.getSize() }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  );
};

export interface CellUpdateOptions {
  onUpdateCellValue?: (
    rowIndex: number,
    columnId: string,
    value: string,
  ) => void;
  onDeleteTableRow?: (tableRowId: number) => void;
}

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableOptionsResolved<TData extends RowData>
    extends CellUpdateOptions {}
}

interface Pagination {
  pageIndex: number;
  pageSize: number;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  getRowId?: (row: TData) => string;
  data?: TData[];
  isLoading?: boolean;
  isError?: boolean;
  pageCount?: number;
  pagination?: Pagination;
  columnVisibility?: Record<string, boolean>;
  setPagination?: Dispatch<SetStateAction<Pagination>>;
  onUpdateCellValue?: (
    rowIndex: number,
    columnId: string,
    value: string,
  ) => void;
  draggableRows?: boolean;
  dataIds?: UniqueIdentifier[];
  subrowsKey?: string;
  isSyncReference?: boolean;
  expanded?: ExpandedState;
  setExpanded?: Dispatch<SetStateAction<ExpandedState>>;
  getRowDepthClassName?: (depth: number) => string | undefined;
  onDeleteTableRow?: (rowId: number) => void;
}

export function DataTableHTML<TData, TValue>({
  columns,
  data = [],
  isLoading = false,
  pageCount,
  pagination,
  setPagination,
  onUpdateCellValue,
  columnVisibility,
  getRowId,
  subrowsKey,
  isSyncReference = false,
  onDeleteTableRow,
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation();
  const topElementRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
    manualPagination: true,
    pageCount,
    onPaginationChange: setPagination,
    state: {
      pagination,
      columnVisibility,
      expanded: true,
    },
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) =>
      subrowsKey ? (row[subrowsKey as keyof TData] as TData[]) : undefined,
    onUpdateCellValue,
    onDeleteTableRow,
  });

  const hasFooter =
    table
      .getFooterGroups()
      .flatMap((group) =>
        group.headers.map((header) => header.column.columnDef.footer),
      )
      .filter(Boolean).length !== 0;

  const { getTableHeaderWidth, setTableHeaderReference } = useTableSizeSync();

  return (
    <div>
      <div ref={topElementRef} />
      <div className="border rounded-md overflow-x-auto overflow-y-hidden">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    ref={(ref) =>
                      isSyncReference && !!ref
                        ? setTableHeaderReference(header.id, ref)
                        : null
                    }
                    style={{
                      width: getTableHeaderWidth(header.id) || undefined,
                    }}
                    data-header-id={header.id}
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {!isLoading && data.length !== 0 && (
              <>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </>
            )}
            {!isLoading && data.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="h-24 text-center">
                  {t('common.no-results')}
                </td>
              </tr>
            )}
          </tbody>
          {data.length !== 0 && (
            <tfoot>
              {hasFooter && (
                <>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <td key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.footer,
                                header.getContext(),
                              )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              )}
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
}

export function DataTable<TData, TValue>({
  columns,
  data = [],
  isLoading = false,
  isError = false,
  pageCount,
  pagination,
  setPagination,
  onUpdateCellValue,
  columnVisibility,
  getRowId,
  dataIds = [],
  draggableRows = false,
  subrowsKey,
  isSyncReference = false,
  getRowDepthClassName,
  expanded,
  setExpanded,
  onDeleteTableRow,
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation();
  const topElementRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
    manualPagination: true,
    pageCount,
    onPaginationChange: setPagination,
    state: {
      pagination,
      columnVisibility,
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) =>
      subrowsKey ? (row[subrowsKey as keyof TData] as TData[]) : undefined,
    onUpdateCellValue,
    onDeleteTableRow,
  });

  const hasFooter =
    table
      .getFooterGroups()
      .flatMap((group) =>
        group.headers.map((header) => header.column.columnDef.footer),
      )
      .filter(Boolean).length !== 0;

  const { getTableHeaderWidth, setTableHeaderReference } = useTableSizeSync();

  return (
    <div>
      <div ref={topElementRef} />
      <div className="border rounded-md overflow-x-auto overflow-y-hidden">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    ref={(ref) =>
                      isSyncReference && !!ref
                        ? setTableHeaderReference(header.id, ref)
                        : null
                    }
                    style={{
                      width: getTableHeaderWidth(header.id) || undefined,
                    }}
                    data-header-id={header.id}
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading && (
              <>
                {repeat(5).map((index) => (
                  <TableRow key={index}>
                    {Array.from({ length: columns.length }).map(
                      (_, cellIndex) => (
                        <TableCell key={cellIndex}>
                          <Skeleton className="w-full h-5" />
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                ))}
              </>
            )}
            {isError && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t('table.error')}
                </TableCell>
              </TableRow>
            )}
            {!isLoading && data.length !== 0 && (
              <>
                {draggableRows ? (
                  <SortableContext
                    items={dataIds}
                    strategy={verticalListSortingStrategy}
                  >
                    {table.getRowModel().rows.map((row) => (
                      <DraggableRow key={row.id} row={row} />
                    ))}
                  </SortableContext>
                ) : (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className={`
                        ${
                          getRowDepthClassName &&
                          getRowDepthClassName(row.depth)
                        } 
                        ${row.getCanExpand() && 'cursor-pointer'}
                      `}
                      onClick={
                        row.getCanExpand()
                          ? row.getToggleExpandedHandler()
                          : undefined
                      }
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                )}
              </>
            )}
            {!isLoading && data.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t('common.no-results')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {data.length !== 0 && (
            <TableFooter>
              {isLoading
                ? hasFooter && (
                    <TableRow>
                      {Array.from({ length: columns.length }).map(
                        (_, cellIndex) => (
                          <TableCell key={cellIndex}>
                            <Skeleton className="w-full h-5" />
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  )
                : hasFooter && (
                    <>
                      {table.getFooterGroups().map((footerGroup) => (
                        <TableRow key={footerGroup.id}>
                          {footerGroup.headers.map((header) => (
                            <TableCell key={header.id}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.footer,
                                    header.getContext(),
                                  )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </>
                  )}
            </TableFooter>
          )}
        </Table>
      </div>
      {pagination && (
        <div
          className={`flex items-center  py-4 ${
            pageCount ? 'justify-between' : 'justify-end'
          }`}
        >
          {pageCount && (
            <p className="ml-2 text-sm font-medium text-muted-foreground">
              {table.getState().pagination.pageIndex + 1} / {pageCount}
            </p>
          )}
          <div className="flex gap-1">
            <div className="self-end">
              <Select
                disabled={
                  !table.getCanPreviousPage() && !table.getCanNextPage()
                }
                value={`${
                  tablePageSizeOptions(t).find(
                    (el) => el.value === table.getState().pagination.pageSize,
                  )!.value
                }`}
                onValueChange={(newValue) => {
                  const newSize = parseInt(newValue, 10);
                  table.setPagination((prevState) => {
                    return {
                      ...prevState,
                      pageIndex: 0,
                      pageSize: newSize,
                    };
                  });
                }}
              >
                <SelectTrigger className="h-9">
                  <SelectValue>
                    {t('table.show')}{' '}
                    {tablePageSizeOptions(t).find(
                      (option) =>
                        option.value === table.getState().pagination.pageSize,
                    )?.label || t('common.all')}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {tablePageSizeOptions(t).map((option) => (
                    <SelectItem key={option.value} value={`${option.value}`}>
                      {t('table.show')} {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                nextTick(() => {
                  topElementRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                });
                table.previousPage();
              }}
              disabled={!table.getCanPreviousPage()}
            >
              {t('table.previous')}
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                nextTick(() => {
                  topElementRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                });
                table.nextPage();
              }}
              disabled={!table.getCanNextPage()}
            >
              {t('table.next')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
