import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import BSTranslation from '@/localization/locale/bs/translations.json';
import RSTranslation from '@/localization/locale/rs/translations.json';
import ENTranslation from '@/localization/locale/en/translations.json';
import SITranslation from '@/localization/locale/si/translations.json';
import HRTranslation from '@/localization/locale/hr/translations.json';

const resources = {
  rs: {
    translation: RSTranslation,
  },
  en: {
    translation: ENTranslation,
  },
  bs: {
    translation: BSTranslation,
  },
  si: {
    translation: SITranslation,
  },
  hr: {
    translation: HRTranslation,
  },
};

i18n.use(initReactI18next).init({
  lng: 'rs',
  fallbackLng: 'rs',
  resources,
});

export default i18n;
