/* eslint-disable */
import { toast } from 'sonner';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Pencil, Trash2 } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import {
  useAuthUserRestaurant,
  useDeleteCompanyLocationGroupMutation,
} from '@durma-soft/gros-sdk';

import {
  LocationGroupWithCompanyLocationsCount,
  ShortRestaurantLocation,
} from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Badge } from '@/components/shared/shadcn-ui/badge';
import { Button } from '@/components/shared/shadcn-ui/button';
import { ConfirmationModal } from '@/components/shared/confirmation-modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';

export const columns = (
  t: TType,
): ColumnDef<LocationGroupWithCompanyLocationsCount>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'company_locations_count',
    header: t('company.groups.count'),
    cell: (cellData) => {
      return (
        <div className="w-full">
          <Badge className="bg-muted border-gray-300 text-gray-700 hover:bg-muted transition-all duration-300">
            {cellData.row.original.company_locations_count}
          </Badge>
        </div>
      );
    },
  },
  {
    accessorKey: 'edit',
    header: '',
    cell: (cellData) => {
      const restaurant = useAuthUserRestaurant();
      const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false);

      const { isPending, mutateAsync } =
        useDeleteCompanyLocationGroupMutation();

      const deleteCompanyGroup = async () => {
        try {
          await mutateAsync({
            restaurant_id: restaurant.id,
            location_group_id: cellData.row.original.id,
          });
          toast.success(t('company.groups.delete-success-msg'));
        } catch (error) {
          toast.error(t('company.groups.delete-error-msg'));
        } finally {
          setIsConfirmationModalOpen(false);
        }
      };

      return (
        <>
          <TooltipProvider delayDuration={100}>
            <div className="flex items-center justify-center gap-5">
              {/* Edit */}
              <Tooltip>
                <TooltipTrigger asChild>
                  <Link
                    replace
                    to={`/restaurant/companies/groups/edit/${cellData.row.original.id}`}
                    className="flex items-center justify-center text-gray-700 "
                  >
                    <Pencil className="w-5 h-5" />
                  </Link>
                </TooltipTrigger>
                <TooltipContent>
                  {t('company.groups.edit-details')}
                </TooltipContent>
              </Tooltip>
              {/* Delete */}
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    className="flex items-center justify-center text-gray-700 p-0"
                    onClick={() => setIsConfirmationModalOpen(true)}
                  >
                    <Trash2 className="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t('common.delete')}</TooltipContent>
              </Tooltip>
            </div>
          </TooltipProvider>
          <ConfirmationModal
            isLoading={isPending}
            isModalOpen={isConfirmationModalOpen}
            confirmationText={t('common.delete')}
            title={t('company.groups.delete')}
            description={t('company.groups.delete-description', {
              group: cellData.row.original.name,
            })}
            onConfirm={deleteCompanyGroup}
            onCancel={() => setIsConfirmationModalOpen(false)}
            closeModal={() => setIsConfirmationModalOpen(false)}
          />
        </>
      );
    },
  },
];

export const locationsColumns = (
  t: TType,
): ColumnDef<ShortRestaurantLocation>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'edit',
    header: '',
    cell: (cellData) => {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="ghost"
                className="flex items-center justify-center text-gray-700 p-0"
                onClick={() => {
                  const { onDeleteTableRow } = cellData.table.options;
                  if (onDeleteTableRow) {
                    onDeleteTableRow(cellData.row.original.id);
                  }
                }}
              >
                <Trash2 className="w-5 h-5" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>{t('common.delete')}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
  },
];
