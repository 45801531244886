import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetCompanyLocationGroupsQuery,
} from '@durma-soft/gros-sdk';

import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { CreateRestaurantCompanyGroupsModal } from '@/components/restaurant/companies/create-restaurant-company-groups-modal';

import { columns } from '@/config/restaurant/company-groups';
import { EditRestaurantCompanyGroupsModal } from '@/components/restaurant/companies/edit-restaurant-company-groups-modal';

const CompanyGroups = () => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const restaurant = useAuthUserRestaurant();
  const { data, isLoading } = useGetCompanyLocationGroupsQuery(restaurant.id);

  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  return (
    <>
      <Helmet>
        <title>{t('company.groups.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('company.groups.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to="/restaurant/companies/groups/create"
              className={buttonVariants({ variant: 'default' })}
            >
              {t('company.groups.create')}
            </Link>
          </div>
          <DataTable
            columns={memoizedColumns}
            data={data || []}
            isLoading={isLoading}
            columnVisibility={{
              edit: hasEditPermission,
            }}
          />
        </div>
      </div>
      <CreateRestaurantCompanyGroupsModal />
      {Boolean(groupId) && <EditRestaurantCompanyGroupsModal key={groupId} />}
    </>
  );
};

export default CompanyGroups;
