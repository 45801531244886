import { useState } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
  useGetNumberOfOrdersPerEmployeeReportQuery,
} from '@durma-soft/gros-sdk';
import { ShortRestaurantLocation } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { repeat } from '@/utils/helpers';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectCompanyLocations } from '@/components/shared/select-company-locations';
import { OrdersByEmployeeModal } from '@/components/restaurant/reports/orders-by-employee-modal';
import { NumberOfCustomerOrders } from '@/components/restaurant/reports/number-of-customer-orders';

import print from '@/lib/print/print';

const RestaurantCustomerOrders = () => {
  const { t } = useTranslation();
  const { employeeId } = useParams();

  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);

  const onUpdateCompanyLocations = (
    newCompanyLocations: ShortRestaurantLocation[],
  ) => {
    setCompanyLocations(newCompanyLocations);
  };

  const restaurant = useAuthUserRestaurant();

  const { from, to, setDateRange } = useDatePickerRangeState();

  const {
    data: companiesAndGroups,
    isSuccess: isSuccess,
    isLoading: isLoading,
  } = useGetAllRestaurantCompaniesQuery(restaurant.id);

  const {
    data: ordersPerEmployee = [],
    isSuccess: isOrdersPerEmployeeSuccess,
    isLoading: isOrdersPerEmpoyeeLoading,
  } = useGetNumberOfOrdersPerEmployeeReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
    company_location_ids: companyLocations.length
      ? companyLocations.map(({ id }) => id)
      : [],
  });

  return (
    <>
      <Helmet>
        <title>{t('reports.restaurant-customers.title')}</title>
      </Helmet>
      {Boolean(employeeId) && <OrdersByEmployeeModal key={employeeId} />}
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.restaurant-customers.title')}
        </h1>
      </div>
      <div className="flex flex-col gap-3 my-5 lg:flex-row ">
        <div className="flex-1 flex flex-col gap-3 lg:flex-row ">
          <div className="self-start">
            <DatePickerWithRange
              date={{ from: new Date(from), to: new Date(to) }}
              setDate={([from, to]) => setDateRange({ from, to })}
            />
          </div>
          <div>
            {isSuccess && (
              <SelectCompanyLocations
                companies={companiesAndGroups.companies}
                groups={companiesAndGroups.groups}
                isLoading={isLoading}
                selectedCompanyLocations={companyLocations}
                onUpdateCompanyLocations={onUpdateCompanyLocations}
              />
            )}
          </div>
        </div>
        <div>
          <PrintButton
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <h1>{t('reports.restaurant-customers.title')} </h1>
                      <p>
                        {format(from, 'dd.MM.yyyy')}
                        {!isEqual(from, to) && ` - ${format(to, 'dd.MM.yyyy')}`}
                      </p>
                    </div>
                    <div className="flex flex-col gap-10">
                      {ordersPerEmployee.map(
                        ({ id, name, users, location_type }) => (
                          <div
                            key={id}
                            className="flex flex-col gap-4 print-in-separate-page"
                          >
                            <h3 className="text-xl font-medium">{name}</h3>
                            <NumberOfCustomerOrders
                              locationType={location_type}
                              forPrint
                              users={users}
                            />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col">
        {isOrdersPerEmpoyeeLoading && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}

        {!isOrdersPerEmpoyeeLoading &&
          ordersPerEmployee.length === 0 &&
          companyLocations.length !== 0 && (
            <p className="text-center">{t('common.no-results')}</p>
          )}

        {companyLocations.length === 0 && (
          <p className="self-center">{t('reports.orders.choose-location')}</p>
        )}

        {isOrdersPerEmployeeSuccess && !isOrdersPerEmpoyeeLoading && (
          <TableSizeSyncProvider>
            <div className="flex flex-col gap-10">
              {ordersPerEmployee.map(
                ({ id, name, location_type, users }, index) => (
                  <div
                    key={id}
                    className="flex flex-col gap-4 print-in-separate-page"
                  >
                    <h3 className="text-xl font-medium">{name}</h3>
                    <NumberOfCustomerOrders
                      isTableSyncReference={index === 0}
                      users={users}
                      locationType={location_type}
                    />
                  </div>
                ),
              )}
            </div>
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default RestaurantCustomerOrders;
