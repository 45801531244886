import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantCompanyGroupsForm } from '@/components/restaurant/companies/create-restaurant-company-groups-form';

export const CreateRestaurantCompanyGroupsModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () =>
    navigate('/restaurant/companies/groups', { replace: true });

  const isModalOpen = Boolean(
    useMatch({ path: '/restaurant/companies/groups/create' }),
  );

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('company.groups.create')}
      description={t('company.groups.create-details')}
    >
      <CreateRestaurantCompanyGroupsForm closeModal={closeModal} />
    </Modal>
  );
};
