import { Minus, Plus } from 'lucide-react';
import { RestaurantManualOrdersFood } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { useOrder } from '@/components/restaurant/manual-orders/manual-orders-provider';

interface ManualOrdersMenuItemProps {
  food: RestaurantManualOrdersFood;
}

export const ManualOrdersMenuItem = ({ food }: ManualOrdersMenuItemProps) => {
  const {
    getFoodQuantity,
    addFoodToOrder,
    removeFoodFromOrder,
    isActive,
    isCancelOrderMutationPending,
    isOrderMutationPending,
  } = useOrder();

  const isPending = isCancelOrderMutationPending || isOrderMutationPending;

  const quantity = getFoodQuantity(food.id);

  const handlePlusPress = () => {
    addFoodToOrder(food.id);
  };

  const handleMinusPress = () => {
    removeFoodFromOrder(food.id);
  };
  return (
    <div className="flex items-center gap-3">
      {food.picture ? (
        <img
          src={food.picture}
          className="size-20 object-cover object-center rounded-md"
          loading="lazy"
          height={80}
          width={80}
          alt=""
        />
      ) : (
        <div className="size-20 bg-muted rounded-md"></div>
      )}
      <div className="flex-1">
        <p className="text-sm">{food.name}</p>
      </div>
      {isActive ? (
        <div className="flex items-center gap-2">
          <button
            type="button"
            disabled={quantity === 0 || isPending}
            onClick={handleMinusPress}
            className="inline-flex size-6 items-center justify-center rounded-full bg-primary/25 hover:bg-primary/30 active:bg-primary/40 transition text-primary disabled:opacity-50 disabled:hover:bg-primary/25 disabled:active:bg-primary/25"
          >
            <Minus size={20} />
          </button>
          <span className="text-sm tabular-nums">{quantity}</span>
          <button
            type="button"
            disabled={isPending}
            onClick={handlePlusPress}
            className="inline-flex size-6 items-center justify-center rounded-full bg-primary hover:bg-primary/90 active:bg-primary/75 transition text-white"
          >
            <Plus size={20} />
          </button>
        </div>
      ) : quantity ? (
        <div className="py-1 px-3 bg-primary/15 rounded-full items-center justify-center inline-flex">
          <span className="text-sm">{quantity}</span>
        </div>
      ) : null}
    </div>
  );
};
